<template>
  <div v-if="loaded">
    <a href="#"
       class="auth-via-google"
       @click.prevent="onGoogleLoginClick"
       v-if="loaded">
      <img alt="Login via Google"
           :src="require('@/assets/images/logos/google.svg')" />
      <span class="title ml-2">{{ title }}</span>
    </a>
  </div>
  <div v-else>
    <button-placeholder />
  </div>
</template>

<script>
import ButtonPlaceholder from './AuthViaGooglePlaceholder.vue'

export default {
  components: {
    ButtonPlaceholder
  },

  props: {
    title: {
      required: true,
      type: String
    }
  },

  data () {
    return {
      loaded: false
    }
  },

  created () {
    const checkGauthLoad = setInterval(() => {
      this.loaded = this.$gAuth.isInit
      if (this.loaded) {
        clearInterval(checkGauthLoad)
      }
    }, 300)
  },

  methods: {
    async onGoogleLoginClick () {
      try {
        const authCode = await this.$gAuth.getAuthCode()

        const response = await this.loginViaGoogle(authCode)
        if (response && response.tfa && response.tfa === true) {
          await this.$router.push({
            name: 'twoFactorAuthenticationCheck',
            params: { token: response.token, loginType: response.loginType }
          })
        } else {
          window.location.href = '/'
        }
      } catch (e) {
        this.showLoginError = true
      }
    },

    ...mapActions('authentication', [
      'loginViaGoogle'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .auth-via-google {
    @include flex-center;

    border: 1px solid #E4E8ED;
    border-radius: 5px;
    text-align: center;
    font-size: 15px;
    height: 44px;

    position: relative;

    img {
      height: 20px;
      position: absolute;
      left: 20px;
    }

    .title {
      font-weight: 500;
    }
  }
</style>
